// extracted by mini-css-extract-plugin
export var activeBreadcrumb = "showcase-module--activeBreadcrumb--19a40 showcase-module--breadcrumb--b61ee";
export var breadcrumb = "showcase-module--breadcrumb--b61ee";
export var breadcrumbs = "showcase-module--breadcrumbs--06913";
export var buttons = "showcase-module--buttons--8de70";
export var content = "showcase-module--content--f79e6";
export var descriptionBox = "showcase-module--descriptionBox--faa63";
export var descriptionBoxLeft = "showcase-module--descriptionBoxLeft--8dd02";
export var descriptionBoxRight = "showcase-module--descriptionBoxRight--06c54";
export var descriptionBoxes = "showcase-module--descriptionBoxes--e6002";
export var dottedLine = "showcase-module--dottedLine--8318c";
export var showcase = "showcase-module--showcase--1e312";